var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"territory-map"},[(_vm.error)?_c('div',{staticClass:"text-danger font-weight-bold"},[_c('span',[_vm._v("Error: "+_vm._s(_vm.error))]),_c('b-button',{staticClass:"text-danger p-0 pl-2 mt-n1",attrs:{"variant":"link"},on:{"click":_vm.clearError}},[_vm._v("x")])],1):_vm._e(),_c('b-button',{staticClass:"btn-show-status position-absolute border-dark text-dark px-2",on:{"click":_vm.toggleIcon}},[(_vm.mapIcon === 'ACTIVITY')?_c('span',{staticClass:"check px-1 bg-success text-white mr-1"},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1):_vm._e(),_c('span',[_vm._v("Show Status")])]),_c('l-map',{staticClass:"map",attrs:{"center":_vm.center,"bounds":_vm.bounds,"options":{ zoomControl: true },"setView":true,"watch":true},on:{"ready":_vm.onReady,"locationfound":_vm.onLocationFound,"locateactivate":_vm.onLocating,"locationerror":_vm.onLocationError}},[_c('l-tile-layer',{attrs:{"url":_vm.url}}),_c('l-locate-control',{ref:"locateControl",attrs:{"options":{
        setView: 'untilPan',
        keepCurrentZoomLevel: true,
        returnToPrevBounds: true,
        flyTo: true,
        strings: {
          popup: 'Yep, you\'re the blue dot.',
        },
      }}},[(_vm.isLocating)?_c('font-awesome-icon',{staticClass:"location-icon text-primary",attrs:{"icon":"circle-notch","spin":""}}):_c('font-awesome-icon',{staticClass:"location-icon text-primary",attrs:{"icon":"location-arrow"}})],1),_vm._l((_vm.territory.addresses),function(x,i){return _c('l-marker',{key:i,attrs:{"lat-lng":_vm.getLatLng(x)},on:{"click":() => _vm.centerMarker(x)}},[(_vm.mapOptions.showSortOrder)?_c('l-icon',[_c('AddressIcon',{attrs:{"index":i+1,"record":x}})],1):_vm._e(),_c('l-popup',{ref:"addressPopup",refInFor:true,attrs:{"options":{ keepInView: true, zIndex: 1100, minWidth: 250 }}},[_c('MapLinks',_vm._g({attrs:{"address":x,"index":i,"territory":_vm.territory,"simple":_vm.mapOptions.simple,"disabled":_vm.disabled}},_vm.$listeners))],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }