var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.disabled)?_c('div'):(_vm.get('type') === 'link')?_c('div',{staticClass:"interaction d-flex justify-content-center align-items-center",class:{ [`bg-${_vm.get('color')}`]: !_vm.invert }},[_c('span',{staticClass:"pl-0"},[_c('b-button',{staticClass:"p-0",attrs:{"disabled":_vm.busy,"variant":"link"},on:{"click":function($event){return _vm.click(_vm.value)}}},[_vm._v(" "+_vm._s(_vm.get('text'))+" ")])],1)]):(_vm.displayOnly || _vm.get('type') === 'fa-icon')?_c('b-button',{staticClass:"interaction",class:{ [`bg-${_vm.get('color')}`]: !_vm.invert, 'display-only': _vm.displayOnly },attrs:{"disabled":_vm.busy,"variant":"link"},on:{"click":function($event){_vm.click(_vm.get('next') || _vm.get('value'))}}},[_c('span',[(_vm.get('icon'))?_c('font-awesome-layers',{staticClass:"fa-layers text-white",class:{
        [`text-${_vm.get('color')}`]: _vm.invert,
        [`${_vm.value}-icon`]: true,
      },on:{"click":function($event){_vm.click(_vm.get('next') || _vm.get('value'))}}},[(!!_vm.get('icon'))?_c('font-awesome-icon',{attrs:{"icon":_vm.get('icon')}}):_vm._e(),(_vm.isSlashed)?_c('font-awesome-icon',{class:{ [`text-${_vm.get('color')}`]: _vm.invert },attrs:{"icon":"slash"}}):_vm._e(),(_vm.isSlashed)?_c('font-awesome-icon',{staticClass:"slash-shadow",class:{ [`text-${_vm.get('color')}`]: !_vm.invert, [`text-${_vm.bg}`]: _vm.bg },attrs:{"icon":"slash"}}):_vm._e(),_c('font-awesome-layers-text',{staticClass:"font-weight-bold",class:{
          [`text-${_vm.get('color')}`]: !_vm.invert,
          'text-white': _vm.invert,
          [`${_vm.value}-text`]: true,
        },attrs:{"value":_vm.get('text')}})],1):_c('span',{class:{
        [`${_vm.value}-text font-16`]: true,
        'text-white': _vm.invert,
      }},[_vm._v(" "+_vm._s(_vm.get('text'))+" ")])],1)]):_c('div',{staticClass:"interaction"},[_c('span',[_vm._v(_vm._s(_vm.get('text')))])])
}
var staticRenderFns = []

export { render, staticRenderFns }