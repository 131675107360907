import { render, staticRenderFns } from "./AddressIcon.vue?vue&type=template&id=5dfd323e&scoped=true"
import script from "./AddressIcon.vue?vue&type=script&lang=js"
export * from "./AddressIcon.vue?vue&type=script&lang=js"
import style0 from "./AddressIcon.vue?vue&type=style&index=0&id=5dfd323e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dfd323e",
  null
  
)

export default component.exports