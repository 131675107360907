var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-address-card",class:{ 'm-0': !_vm.disabled, 'm-0 pb-3': _vm.mode === 'phone-list', 'p-2': _vm.isDesktop && _vm.mode === 'phone-list' }},[_c('AddressCard',{class:{
      'border-warning border-medium active': _vm.isActiveAddress(_vm.address.id),
      'bg-secondary border-right-0 border-left-0': _vm.mode === 'phone-list',
      'bg-white': _vm.mode === 'address-list',
    },attrs:{"mode":_vm.mode,"index":_vm.index,"address":_vm.address,"territoryId":_vm.territory.id}}),(_vm.mode === 'phone-list')?_c('div',[_vm._l((_vm.phones),function(phone,index){return _c('div',{key:index},[_c('PhoneCard',{staticClass:"bg-white",class:{
          'border-warning border-medium active': _vm.isActiveAddress(phone.id),
        },attrs:{"index":index,"phoneRecord":phone,"address":_vm.address,"incomingResponse":phone.lastActivity,"disabled":_vm.disabled}})],1)}),_c('b-list-group',[(_vm.mode === 'phone-list')?_c('b-list-group-item',{staticClass:"new-phone d-flex p-0 border-0",class:{ 'pt-0': _vm.isDesktop, 'mt-2': !_vm.isDesktop }},[_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"text-gray bg-white"},[_c('font-awesome-icon',{attrs:{"icon":"phone-alt"}})],1)],1),_c('the-mask',{staticClass:"form-control phone-input",attrs:{"type":"tel","mask":'###-###-####',"masked":false},nativeOn:{"mousedown":function($event){return _vm.onActive.apply(null, arguments)}},model:{value:(_vm.newPhone),callback:function ($$v) {_vm.newPhone=$$v},expression:"newPhone"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"text-white",attrs:{"variant":"success","disabled":_vm.isAdding},on:{"click":_vm.addNewPhone}},[(_vm.isAdding)?_c('font-awesome-icon',{attrs:{"icon":"circle-notch","spin":""}}):_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)],1)],1):_vm._e()],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }