var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags w-100 mt-2",class:{
    'd-none': !_vm.selectedTags.length && !_vm.availableTags.length,
    'px-0': _vm.$route.name === 'map-view',
    'px-2': _vm.$route.name !== 'map-view',
  }},[_c('div',{staticClass:"w-100 text-left"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('div',{staticClass:"combined-tags d-flex flex-wrap text-left overflow-auto",class:{
          'vh-37': !_vm.isDesktop && !_vm.isCheckedOut && _vm.$route.name === 'address-detail',
          'vh-22': !_vm.isDesktop && _vm.isCheckedOut && _vm.$route.name === 'address-detail',
        }},[_vm._l((_vm.displayedTags),function(tag,index){return _c('b-badge',{key:index,staticClass:"badge tag-button d-flex mr-2 mb-2 p-2 border-0",class:{
            active: false,
            'bg-danger': tag.state && _vm.highlight(tag.caption),
          },attrs:{"size":"sm","variant":tag.state && (_vm.highlight(tag.caption) ? 'danger' : 'light')}},[_c('span',{staticClass:"tag-text d-flex align-items-center small"},[(tag.state && _vm.isEditable)?_c('font-awesome-icon',{staticClass:"tag-icon mr-1",attrs:{"icon":"times"},on:{"click":() => _vm.updateTag(tag)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.formatLanguage(tag.caption, _vm.language))+" ")],1)])}),(_vm.isEditable)?_c('TagConfirm',{attrs:{"id":_vm.record.id,"record":_vm.record,"available-tags":_vm.filteredTags}}):_vm._e()],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }