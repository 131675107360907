var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address-card-container min-height d-flex justify-content-center py-2",class:{
    'min-height-phone-address': _vm.$route.name === 'phone-list',
    'p-2': _vm.$route.name === 'phone-list',
    'px-2 min-height': _vm.$route.name === 'address-list',
  }},[_c('div',{staticClass:"w-100 row"},[_c('div',{staticClass:"address-card row justify-content-between align-items-start ml-0 mr-0 w-100",class:{
        'min-height text-dark': _vm.$route.name === 'address-list',
        'text-white': _vm.$route.name === 'phone-list',
        'col-12 p-0': _vm.mode === 'map-view',
      }},[_c('div',{staticClass:"d-flex pb-1",class:{ 'w-75': _vm.$route.name === 'map-view' }},[_c('AddressIcon',{attrs:{"index":_vm.index+1,"record":_vm.address}}),_c('div',{staticClass:"pl-2"},[_c('div',{staticClass:"address d-flex align-items-center"},[_c('div',{staticClass:"d-inline mb-0"},[_vm._v(_vm._s(_vm.address.addr1)+" ")]),_vm._v(" "+_vm._s(_vm.address.addr2)+" ")]),_c('div',{staticClass:"text-left city-state-zip mb-1"},[_vm._v(" "+_vm._s(_vm.address.city)+" "+_vm._s(_vm.address.state_province)+" "+_vm._s(_vm.address.postal_code)+" ")])])],1),_c('b-dropdown',{attrs:{"variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true}])},[(_vm.$route.name === 'map-view')?_c('b-dropdown-item',{on:{"click":_vm.startHere}},[_vm._v(" Reorder - Start Here ")]):_vm._e(),(_vm.$route.name === 'map-view')?_c('b-dropdown-item',{on:{"click":_vm.endHere}},[_vm._v(" Reorder - End Here ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"href":_vm.mapsUrl,"target":"_blank"}},[_vm._v(" Get Driving Direction ")]),_c('b-dropdown-item',{attrs:{"href":_vm.lookupFastPeopleSearch,"target":"_blank"}},[_vm._v(" Lookup Fast People Search ")]),(_vm.canWrite)?_c('b-dropdown-item',{attrs:{"to":{
            name: 'address-edit',
            params: { territoryId: _vm.territoryId, addressId: _vm.address.id, mode: 'edit' },
            query: { origin: _vm.$route.name }
          }}},[_vm._v(" Edit Address ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"to":{
            name: 'activity-history',
            params: { territoryId: _vm.territoryId, addressId: _vm.address.id, checkoutId: _vm.checkoutId },
            query: { origin: _vm.$route.name }
          }}},[_vm._v(" See History ")]),(_vm.canWrite)?_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.removeAddress}},[_vm._v(" Delete ")]):_vm._e()],1),_c('Tags',_vm._g({class:{'pl-2': _vm.$route.name === 'phone-list'},attrs:{"record":_vm.address,"variant":_vm.$route.name === 'phone-list' ? 'info' : 'primary',"addressIndex":_vm.index}},_vm.$listeners)),(_vm.mode!=='phone-list')?_c('ActivityLog',{attrs:{"entity":_vm.address}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }