var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-bar sticky-top bg-light p-2",style:({ top: _vm.top })},[_c('div',{class:{
    'd-flex justify-content-between': _vm.showFilter,
    'position-relative': !_vm.showFilter,
    }},[_c('b-form-input',{staticClass:"d-inline pr-5",attrs:{"placeholder":_vm.searchText},on:{"keydown":_vm.keydown},model:{value:(_vm.keywordFilter),callback:function ($$v) {_vm.keywordFilter=$$v},expression:"keywordFilter"}}),_c('div',{staticClass:"buttons",class:{
      'align-items-center d-flex justify-content-between mx-0': _vm.showFilter,
      'position-absolute mr-1 mt-1 d-inline': !_vm.showFilter,
    }},[(_vm.showSearch)?_c('b-button',{staticClass:"search-btn bg-transparent border-0",class:{ 'text-light no-pointer': !_vm.keywordFilter },attrs:{"title":"Search","disabled":!_vm.keywordFilter},on:{"click":_vm.search}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"search"}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }