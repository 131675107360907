import { render, staticRenderFns } from "./PublisherToken.vue?vue&type=template&id=020dac20&scoped=true"
import script from "./PublisherToken.vue?vue&type=script&lang=js"
export * from "./PublisherToken.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020dac20",
  null
  
)

export default component.exports