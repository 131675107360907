var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"territory"},[(_vm.territoryIsLoading)?_c('Loading'):_c('div',[(_vm.collaborate)?_c('div',{staticClass:"collaboration-mode"},[_vm._v("Collaboration Mode")]):_vm._e(),_c('header',{staticClass:"page-header sticky-top w-100 pt-2 pb-0",attrs:{"sticky":true}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"w-100 px-2"},[_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.territoryName))]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('h4',{staticClass:"text-truncate font-weight-bold"},[_vm._v(" "+_vm._s(_vm.territoryDescription)+" ")]),(_vm.displayType !== 'Regular')?_c('b-badge',{staticClass:"ml-2",attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.displayType)+" ")]):_vm._e()],1),_c('b-dropdown',{attrs:{"variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true}])},[(_vm.$route.name === 'map-view')?_c('b-dropdown-item',{on:{"click":_vm.optimizeNearMe}},[_vm._v(" Reorder Addresses ")]):_vm._e(),(_vm.$route.name === 'map-view')?_c('b-dropdown-divider',{staticClass:"d-block"}):_vm._e(),(_vm.isCheckedOut)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.checkIn(true)}}},[_vm._v(" Check-in Territory ")]):_vm._e(),(_vm.canWrite)?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'address-new-terr',
                  params: { territoryId: _vm.territoryId, mode: 'add' },
                  query: { origin: _vm.$route.name },
                }}},[_vm._v(" Add New Address ")]):_vm._e(),(_vm.isAuthenticated)?_c('b-dropdown-item',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.href),expression:"href",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.urlCopied),expression:"urlCopied",arg:"success"}]},[_vm._v(" Copy Link To Territory ")]):_vm._e(),(_vm.isCheckedOut)?_c('b-dropdown-item',{on:{"click":_vm.toggleCollaborate}},[(_vm.collaborate)?_c('span',[_vm._v("Turn Off")]):_c('span',[_vm._v("Turn On")]),_vm._v(" Collaborate Mode ")]):_vm._e(),(_vm.$route.name === 'map-view' && _vm.canManage)?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'optimize',
                  params: { territoryId: _vm.territoryId },
                  query: { origin: _vm.$route.name },
                }}},[_vm._v(" Optimizer ")]):_vm._e(),(_vm.canManage)?_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.reset}},[_vm._v(" Reset Territory ")]):_vm._e()],1)],1),_c('div',{staticClass:"checkout-info d-flex justify-content-between my-1"},[_c('span',[_vm._v(_vm._s(_vm.currentPublisher))])])]),_c('div',{staticClass:"header-buttons w-100 d-flex justify-content-between pt-2 pl-2"},[_c('b-nav',{attrs:{"tabs":"","fill":""}},[_c('b-nav-item',{staticClass:"px-2 text-nowrap",attrs:{"to":{ name: 'address-list', params: { territoryId: _vm.territoryId } },"pressed":_vm.viewMode === 'address-list'}},[_c('font-awesome-icon',{attrs:{"icon":"bars"}}),_c('span',{staticClass:"pl-2"},[_vm._v("List ("+_vm._s(_vm.addressCount)+")")])],1),_c('b-nav-item',{staticClass:"px-2 text-nowrap",attrs:{"to":{ name: 'map-view', params: { territoryId: _vm.territoryId } },"pressed":_vm.viewMode==='map-view'}},[_c('font-awesome-icon',{attrs:{"icon":"map-marked-alt"}}),_c('span',{staticClass:"pl-2"},[_vm._v("Map ("+_vm._s(_vm.addressCount)+")")])],1),_c('b-nav-item',{staticClass:"px-2 text-nowrap",attrs:{"to":{ name: 'phone-list', params: { territoryId: _vm.territoryId } },"pressed":_vm.viewMode === 'phone-list'}},[_c('font-awesome-icon',{attrs:{"icon":"mobile-alt"}}),_c('span',{staticClass:"pl-2"},[_vm._v("Phone ("+_vm._s(_vm.phoneCount)+")")])],1)],1)],1)])]),_c('router-view',{ref:_vm.viewMode,staticClass:"router-view",attrs:{"disabled":!_vm.isCheckedOut,"options":{ showSortOrder: true, editable: true }},on:{"update-count":_vm.updateCount,"locating":_vm.onLocating,"location-found":_vm.onLocationFound,"location-error":_vm.onLocationError,"on-end-here-clicked":_vm.locate}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }