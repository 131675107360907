<template>
  <div>
    <h3 class="p-5 text-center">
      <font-awesome-icon icon="circle-notch" spin></font-awesome-icon>
      Please wait...
    </h3>
  </div>
</template>
<script>
export default {
  name: 'Loading',
};
</script>
